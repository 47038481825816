@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "variables";
@import "text-stroke";
//@import "fonts";

@font-face {
  font-family: 'Itim';
  src: url("../font/Itim-Regular.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Itim";
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-curve {
  border-radius: 3rem;
}

.btn svg {
  margin-bottom: 0.25rem;
  margin-right: 0.5rem;
}

.root-container {
  height: 100vh;
  background: linear-gradient(0deg, $base-color-1 -40%, #00bdff 100%);

  .root-wrapper {
    height: 100vh;
    background: transparent url("../images/bt-bg.png") repeat-x bottom right;
    background-size: auto 20%;
  }
}

.page-container {
  height: 100vh;
  overflow: hidden;
}

.page-home {
  display: flex;
  justify-content: center;
  align-items: center;

  .page-home-wrapper {
    padding-top: 2rem;
    padding-bottom: 4rem;
  }

  .booth-name {
    margin-bottom: 1.2rem;
    img {
      display: block;
      width: 60%;
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .logo-wrapper {
    margin-bottom: 1.5rem;

    img {
      display: block;
      width: 100px;
      height: auto;
      padding: 0.25rem;
      margin-left: auto;
      margin-right: auto;
      background-color: #ffffff;
      border-radius: 50%;
    }
  }
  .text-wrapper {
    margin-bottom: 2rem;
    h1, h2, h3 {
      color: #ffffff;
      text-align: center;
      @include text-stroke(2, #0061a9);
    }
    h1 {
      font-size: 1.8rem;
      font-weight: bold;
      letter-spacing: 2px;
    }
    h2 {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
    h3 {
      font-size: 1.5rem;
      font-weight: normal;
      span {
        display: block;
        font-size: 1rem;
        font-weight: normal;
      }
    }
  }
  .action-wrapper {
    text-align: center;

    .btn {
      font-size: 1.5rem;
      font-weight: normal;
      padding: 0.5rem 2rem;

      svg {
        margin-right: 1.5rem;
      }
    }
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

.page-finished {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .page-finished-wrapper {
    .checker {
      margin-bottom: 2rem;
      svg {
        display: block;
        width: 150px;
        margin: 40px auto 0;
      }

      .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 0;

        &.circle {
          animation: dash .9s ease-in-out;
        }

        &.line {
          stroke-dashoffset: 1000;
          animation: dash .9s .35s ease-in-out forwards;
        }

        &.check {
          stroke-dashoffset: -100;
          animation: dash-check .9s .35s ease-in-out forwards;
        }
      }
    }
    .text-wrapper {
      margin-bottom: 2rem;
      p {
        color: #ffffff;
        font-size: 2rem;
        text-align: center;
        margin-bottom: 0;
      }
    }
    .btn {
      font-size: 2rem;
      padding: 0.5rem 3rem;
    }
  }
}

.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .layout-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 60px;
    line-height: 1;
    padding: 15px;
    margin-bottom: 2rem;
    background: linear-gradient(0deg, #4fc1fb 0%, #a6e1ff 100%);
    box-shadow: 0 0 40px rgba(0, 158, 202, 0.68);

    span {
      font-size: 1.6rem;
      color: #004086;
      font-weight: 500;
      text-shadow: 0 0 15px #fff;
      margin-bottom: 0;
      line-height: 1;
    }
  }
  .layout-content {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .wp {
      width: 90%;
    }

    .container-fluid {
      padding: 30px;
      max-width: 800px;
      background-color: rgba(#ffffff, 0.3);
      border-radius: 5px;
    }

    .custom-control:not(:last-child) {
      margin-bottom: 0.8rem;
    }

    .custom-control-label {
      color: #fff;
      font-size: 1.2rem;
      font-weight: 600;
      padding-left: 1rem;
      letter-spacing: 2px;
      text-shadow: 0 0 30px #00838e;
      @include text-stroke(2, #0061a9);

      &:before,
      &:after {
        width: 1.4rem;
        height: 1.4rem;
        top: 0.2rem;
      }
    }

    .btn-wrapper {
      position: fixed;
      left: 50%;
      bottom: 200px;
      transform: translateX(-50%);
      z-index: 999;
      .btn {
        font-size: 1.2rem;
        span {
          position: relative;
          top: -1px;
          display: inline-block;
          font-size: 1rem;
          font-weight: normal;
          margin-left: 0.5rem;
          //margin-bottom: 0.25rem;
          vertical-align: middle;
        }
      }
    }


  }
  .layout-footer {
    display: flex;
    align-items: center;
    min-height: 60px;

    .dot-wrapper {
      display: flex;
      justify-content: space-around;
      width: 100%;

      .dot {
        color: #ff002d;
        text-align: center;
        font-size: 2rem;
        line-height: 1;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: $base-color-2;
        border: 3px solid #fff;
        transition: background-color 0.5s ease-in-out;

        &.current,
        &.success {
          background-color: $base-color-1;
        }

        svg {
          position: relative;
          top: -10px;
          left: -5px;
        }
      }
    }
  }
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, $base-color-1 -40%, #00bdff 100%);
  z-index: 99999;
}